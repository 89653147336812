import React from 'react';

import {Admin, Resource, ListGuesser, EditGuesser, CreateGuesser, required} from 'react-admin';
import {ArrayInput, SimpleFormIterator, AutocompleteInput, AutocompleteArrayInput} from 'react-admin';

import {useState, useEffect} from 'react';
import {useForm} from 'react-final-form'

import jsonServerProvider from 'ra-data-json-server';

import dataProvider, {getImageBlob} from './dataProvider';
import authProvider from './authProvider';
import {usePermissions} from 'react-admin';

import {DeviceIdentifier, RoomWithBuildingIdentifier, formatDate} from './common.js';

import {OrganizationList, OrganizationCreate, OrganizationEdit} from './resources/organization.js';
import {BuildingList, BuildingCreate, BuildingEdit} from './resources/building.js';
import {RoomList, RoomCreate, RoomEdit} from './resources/room.js';
import {
  DeviceList,
  DeviceCreate,
  DeviceEdit,
  DeviceEventTypeList,
  DeviceEventTypeEdit,
  DeviceEventTypeCreate, DeviceEventCreate, DeviceEventEdit, DeviceEventList
} from './resources/device.js';
import {DeviceModelConfigList, DeviceModelConfigCreate, DeviceModelConfigEdit} from './resources/devicemodelconfig.js';
import {PreferencesEdit, PreferencesList} from './resources/userpreferences';
import {AnnotationList, AnnotationCreate, AnnotationEdit} from './resources/annotation.js';
import {UserList, UserCreate, UserEdit} from './resources/user.js';
import {AlarmList, AlarmCreate, AlarmEdit} from './resources/alarm.js';
import {AlarmFeedbackList, AlarmFeedbackCreate, AlarmFeedbackEdit} from './resources/alarmfeedback.js';
import {ModelTemplateCreate, ModelTemplateEdit, ModelTemplateList} from "./resources/modelTemplate";
import {AssetCreate, AssetEdit, AssetList, AssetParentIdentifier} from "./resources/asset.js";
import {DeviceDeploymentCreate, DeviceDeploymentList, DeviceDeploymentEdit} from "./resources/devicedeployment.js";

import {
  StandardPagination
} from './common.js'

import {
  List,
  Datagrid,
  EmailField,
  TextField,
  ReferenceField,
  DateField,
  FunctionField,
  NumberField,
  UrlField,
  BooleanField,
  ArrayField,
  SingleFieldList,
  ReferenceArrayField,
  ChipField,
} from 'react-admin';

import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  DateInput,
  SelectArrayInput,
  NumberInput,
  SearchInput,
  ReferenceArrayInput,
  DateTimeInput,
} from 'react-admin';

import {JsonField, JsonInput} from "react-admin-json-view";

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {Title} from 'react-admin';

import {Show, SimpleShowLayout, ImageField, number} from 'react-admin';

import {
  IntegrationCreate, IntegrationEdit,
  IntegrationList,
  IntegrationServiceCreate,
  IntegrationServiceEdit,
  IntegrationServiceList
} from "./resources/integration";
import {VpnServerCreate, VpnServerList} from "./resources/vpnserver";
import {GatewayConfigCreate, GatewayConfigEdit, GatewayConfigList} from "./resources/gatewayconfig";
import {AssetMetricsTagCreate, AssetMetricsTagEdit, AssetMetricsTagList} from "./resources/assetMetrics";



export const DevicetypeList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="brand"/>
      <TextField source="id"/>
      <TextField source="model"/>
      <DateField source="registered_at"/>
      <TextField source="registered_by"/>
    </Datagrid>
  </List>
);

export const DevicetypeCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="brand"/>
      <TextInput source="model"/>
    </SimpleForm>
  </Create>
);

const SpectrogramField = ({source, record}) => {
  const [data, updateData] = useState();
  useEffect(() => {
    const getData = async () => {
      const url = record[source]; // .replace('/download', '');
      const blob = await getImageBlob(url);

      updateData({blob: blob});
    }
    getData();
  }, []);

  if (!data) {
    return null;
  }

  const width = 32;
  const style = {
    transform: `rotate(-90deg) translate(-${width}px)`,
    "transform-origin": "top left",
    width: `${width}px`,
  }

  return <img src={data.blob} style={style}></img>
}

export const SpectrogramShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="title"/>
      <TextField source="filename"/>
      <DateField label="Stored time" source="created_at" showTime/>
      <DateField label="Start time" source="start_time" showTime/>
      <DateField label="End time" source="end_time" showTime/>
      <SpectrogramField source="download_url"/>
    </SimpleShowLayout>
  </Show>
);

export const renderSpectrogramType = (record) => {
  const sr = record.samplerate;
  const bands = record.bands;
  const hop = record.hop_length;
  // TODO: also include type

  return `sr${sr}hop${hop}bands${bands}`;
}

export const renderDevice = (record) => {
  return `${record.serial}`;
}

export const SpectrogramList = props => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="id"/>
      <ReferenceField source="device_id" reference="devices">
        <FunctionField render={renderDevice}/>
      </ReferenceField>
      <DateField source="start_time" showTime/>
      <DateField source="end_time" showTime/>
      <DateField source="created_at" showTime/>

      <ReferenceField source="spectrogram_type_id" reference="spectrogramtypes">
        <FunctionField render={renderSpectrogramType}/>
      </ReferenceField>

      <TextField source="bucket"/>
      <TextField source="filename"/>
      <UrlField source="download_url"/>
    </Datagrid>
  </List>
);

export const SpectrogramtypeList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="short_id"/>
      <TextField source="id"/>
      <NumberField source="samplerate"/>
      <NumberField source="fft_length"/>
      <NumberField source="hop_length"/>
      <NumberField source="bands"/>
      <NumberField source="fmin"/>
      <NumberField source="fmax"/>
    </Datagrid>
  </List>
);

export const DevicesMetricList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <ReferenceField source="device_id" reference="devices"><TextField source="serial"/></ReferenceField>
      <TextField source="time"/>
      <TextField source="data"/>
      <DateField source="created_at" showTime/>
    </Datagrid>
  </List>
);

export const Dashboard = (props) => (
  <Card>
    <Title title="Data dashboard"/>
    <CardContent>The data dashboard is at app.soundsensing.no</CardContent>
    <CardActions>
      <Button size="small" href="https://app.soundsensing.no">Go there</Button>
    </CardActions>
  </Card>
);

export const WebhookList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <BooleanField source="active"/>
      <TextField source="auth.authentication_type"/>
      <ReferenceField label="Creator" source="creator.id" reference="users"><TextField
        source="email"/></ReferenceField>
      <TextField source="description"/>
      <ArrayField source="devices">
        <Datagrid> <ReferenceField label="Serial" source="id" reference="devices"><TextField
          source="serial"/></ReferenceField></Datagrid>
      </ArrayField>
      <ArrayField source="event_subscriptions">
        <SingleFieldList><ChipField source="event_type"/></SingleFieldList>
      </ArrayField>
      <TextField source="id"/>
      <TextField source="name"/>
      <ReferenceField source="organization_id" reference="organizations"><TextField source="id"/></ReferenceField>
      <DateField source="registered_at"/>
      <TextField source="registered_by"/>
      <UrlField source="url"/>
    </Datagrid>
  </List>
);

export const WebhookCreate = props => {

  const eventTypes = [
    {id: "device", name: "Device"},
    {id: "device_metric", name: "Device Metric"},
    {id: "spectrogram", name: "Spectrogram"},
    {id: "soundlevel", name: "Soundlevel"},
    {id: "classification", name: "Classification"},
    {id: "audio_clip", name: "Audio Clip"}
  ];

  return <Create {...props}>
    <SimpleForm>
      <TextInput source="name"/>
      <TextInput source="url"/>
      <TextInput source="description"/>
      <BooleanInput source="active" defaultValue={true}/>
      <TextInput label="Secret" source="auth.secret"/>
      <TextInput label="Header Key" source="auth.header_key"/>
      <TextInput label="Header Value" source="auth.header_value"/>
      <ArrayInput source="devices">
        <SimpleFormIterator>
          <ReferenceInput label="Device" reference="devices" perPage={10000}>
            <AutocompleteInput optionText={DeviceIdentifier}/>
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
      <SelectArrayInput choices={eventTypes} source="event_subscriptions"/>
      <ReferenceInput source="organization_id" reference="organizations"><SelectInput
        optionText="name"/></ReferenceInput>
    </SimpleForm>
  </Create>
};

export const WebhookEdit = props => (

  <Edit {...props}>
    <SimpleForm>
      <BooleanInput source="active"/>
    </SimpleForm>
  </Edit>
);

export const DeviceCalibrationList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <ReferenceField source="device_id" reference="devices"><TextField source="serial"/></ReferenceField>
      <DateField source="start_time" label='Start Time' showTime/>
      <DateField source="end_time" label='End Time' showTime/>
    </Datagrid>
  </List>
);

export const DeviceCalibrationCreate = props => {

  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput label='Device' reference='devices' source="device" perPage={10000}>
          <SelectInput optionText="serial"/>
        </ReferenceInput>
        <DateTimeInput source="start_time" helperText="Leave blank end time for open interval"
                       defaultValue={new Date()}
                       showTime/>
        <DateTimeInput source="end_time" showTime/>
        <NumberInput source="correction"/>
      </SimpleForm>
    </Create>
  )
};


export const ModelTypeList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="name"/>
      <TextField source="description"/>
    </Datagrid>
  </List>
);


export const AssetCategoryCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name"/>
    </SimpleForm>
  </Create>
);

export const AssetCategoryList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id"/>
      <TextField source="name"/>
    </Datagrid>
  </List>
);

export const AssetCategoryEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id"/>
      <TextInput source="name"/>
    </SimpleForm>
  </Edit>
);

export const RoomPositionCreate = props => (
  // not in use right now, will be used later. Lacks support in backend (API).
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="room_id" reference="rooms"><SelectInput
        optionText="id"/></ReferenceInput>
      <NumberInput source="install_height"/>
      <NumberInput source="floor_height"/>
      <NumberInput source="roll"/>
      <NumberInput source="pitch"/>
      <NumberInput source="yaw"/>
      <TextInput source="description"/>
    </SimpleForm>
  </Create>
);


export const DeviceCalibrationEdit = props => (

  <Edit {...props}>
    <SimpleForm>
      <DateTimeInput source="start_time" defaultValue={new Date()} showTime/>
      <NumberInput source="correction"/>
    </SimpleForm>
  </Edit>
);






export const ModelInstanceList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField label="Id" source="id"/>
      <ReferenceField source="model_template_id" reference="ml/templates" label='Model Template'>
        <FunctionField render={template => `${template.name}(version ${template.version})`}/>
      </ReferenceField>
      <ReferenceField source="model_training_run_id" reference="ml/trainingruns"
                      label='Model Training Run'><TextField source="id"/></ReferenceField>
      <DateField source="created_at" label='Created at' showTime/>
      <TextField source="saved_model" label='Saved Model'/>
      <TextField source="evaluation_metrics" label='Evaluation Metrics'/>
      <TextField source="meta" label='Metadata'/>
    </Datagrid>
  </List>
);

//TODO: name + version on template select instead of id?
export const ModelInstanceCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput label='Model Template' reference='ml/templates' source="model_template_id" perPage={1000}>
        <SelectInput optionText="id"/>
      </ReferenceInput>
      <ReferenceInput label='Model Training Run' reference='ml/trainingruns' source="model_training_run_id"
                      perPage={1000}>
        <SelectInput optionText="id"/>
      </ReferenceInput>
      <TextInput source="saved_model" label="Saved Model"/>
      <TextInput source="evaluation_metrics" label="Evaluation Metrics"/>
      <TextInput source="meta" label="Metadata"/>
    </SimpleForm>
  </Create>
);

export const ModelTrainingRunList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField label="Id" source="id"/>
      <ReferenceField source="model_template_id" reference="ml/templates" label='Model Template'>
        <FunctionField render={template => `${template.name}(version ${template.version})`}/>
      </ReferenceField>
      <ReferenceField source="device_id" label='Training Device' reference="devices"><TextField
        source="serial"/></ReferenceField>
      <DateField source="data_start" label='Start Time' showTime/>
      <DateField source="data_end" label='End Time' showTime/>
      <DateField source="created_at" label='Created at' showTime/>
      <TextField source="meta" label='Metadata'/>
    </Datagrid>
  </List>
);

//TODO: name + version on template select instead of id?
export const ModelTrainingRunCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput label='Model Template' reference='ml/templates' source="model_template_id" perPage={1000}>
        <SelectInput optionText="id"/>
      </ReferenceInput>
      <ReferenceInput label='Device' reference='devices' source="device_id" perPage={10000}>
        <SelectInput optionText="serial"/>
      </ReferenceInput>
      <DateTimeInput source="data_start" label='Training data start' defaultValue={null} format={formatDate}/>
      <DateTimeInput source="data_end" label='training data end' defaultValue={null} format={formatDate}/>
      <TextInput source="meta" label="Metadata"/>
    </SimpleForm>
  </Create>
);


function App({permissions}) {

  console.log('App.permissions', permissions);

  return (
    <Admin dashboard={Dashboard}
           disableTelemetry
           dataProvider={dataProvider}
           authProvider={authProvider}>

      {permissions => [
        <Resource name="annotations" list={AnnotationList} create={AnnotationCreate} edit={AnnotationEdit}/>,

        permissions && permissions.has('admin') ?
          <Resource name="alarm" list={AlarmList} edit={AlarmEdit} create={AlarmCreate}/>
          : <Resource name="alarm" list={AlarmList}/>,

        <Resource name="alarmfeedback" options={{"label": "Alarm Feedback"}} list={AlarmFeedbackList}
                  create={AlarmFeedbackCreate} edit={AlarmFeedbackEdit}/>,

        <Resource name="devices" list={DeviceList} edit={DeviceEdit} create={DeviceCreate}/>,

        <Resource name="devicetypes" list={DevicetypeList} create={DevicetypeCreate}/>,

        <Resource name="spectrograms" list={SpectrogramList} show={SpectrogramShow}/>,

        <Resource name="spectrogramtypes" list={SpectrogramtypeList}/>,

        <Resource name="audioclip" list={ListGuesser}/>,
        // This is an empty Resource now needed after updating react-admin
        // TODO: make our own Audioclip List-component instead of using ListGuesser,
        //       and remove this uploaders-Resource.
        <Resource name="uploaders"/>,

        <Resource name="devices_metrics" list={DevicesMetricList}/>,
        <Resource name="integration" options={{"label": "Integrations"}}
                  list={IntegrationList} create={IntegrationCreate} edit={IntegrationEdit}/>,

        <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate}/>,

        <Resource name="preferences" list={PreferencesList} edit={PreferencesEdit}/>,

        //Webhook hidden behind admin because it requires access to organizations, which is also currently hidden behind admin
        permissions && permissions.has('admin')
          ? <Resource name="webhooks" list={WebhookList} create={WebhookCreate} edit={WebhookEdit}/>
          : null,

        permissions && permissions.has('admin') ?
          <Resource name="organizations"
                    list={OrganizationList}
                    edit={OrganizationEdit}
                    create={OrganizationCreate}/>
          : null,
        permissions && permissions.has('admin') ?
          <Resource name="buildings" list={BuildingList} create={BuildingCreate} edit={BuildingEdit}/> : null,

        permissions && permissions.has('admin') ?
          <Resource name="rooms" list={RoomList} edit={RoomEdit} create={RoomCreate}/> : null,


        permissions && permissions.has('admin') ?
          <Resource name="asset_categories" options={{"label": "Asset Categories"}} list={AssetCategoryList}
                    edit={AssetCategoryEdit} create={AssetCategoryCreate}/> : null,

        permissions && permissions.has('admin') ?
          <Resource name="assets" list={AssetList} edit={AssetEdit} create={AssetCreate}
                    options={{"label": "Assets"}}/> : null,

        permissions && permissions.has('admin') ?
          <Resource name="devicedeployments" list={DeviceDeploymentList} create={DeviceDeploymentCreate}
                    edit={DeviceDeploymentEdit}/> : null,

        permissions && permissions.has('admin') ?
          <Resource name="devicecalibrations" list={DeviceCalibrationList} create={DeviceCalibrationCreate}
                    edit={DeviceCalibrationEdit}/>
          : null,

        permissions && permissions.has('admin') ?
          <Resource name="vpnservers" list={VpnServerList} create={VpnServerCreate}/>
          : null,

        permissions && permissions.has('admin') ?
          <Resource name="gateways" list={GatewayConfigList} create={GatewayConfigCreate}
          edit={GatewayConfigEdit}/>
          : null,

        <Resource name="devicemodelconfig" options={{"label": "Device model configs"}} list={DeviceModelConfigList}
                  create={DeviceModelConfigCreate} edit={DeviceModelConfigEdit}/>,

        <Resource name="deviceeventtypes" options={{"label": "Device Event Types"}} list={DeviceEventTypeList}
                  create={DeviceEventTypeCreate}/>,

        <Resource name="deviceevents" options={{"label": "Device Events"}} list={DeviceEventList}
                  create={DeviceEventCreate} edit={DeviceEventEdit}/>,

        <Resource name="ml/templates" options={{"label": "Model Templates"}} list={ModelTemplateList}
                create={ModelTemplateCreate} edit={ModelTemplateEdit}/>,

        <Resource name="assetmetrictags" options={{"label": "Asset Metric Tags"}} list={AssetMetricsTagList}
                create={AssetMetricsTagCreate} edit={AssetMetricsTagEdit}/>,

        permissions && permissions.has('admin') ?
          <Resource name="modeltype" options={{"label": "Model Types"}} list={ModelTypeList}/>
          : null,


        permissions && permissions.has('admin') ?
          <Resource name="ml/instances" options={{"label": "Model Instances"}} list={ModelInstanceList}
                    create={ModelInstanceCreate}/>
          : null,

        permissions && permissions.has('admin') ?
          <Resource name="ml/trainingruns" options={{"label": "Model Training Runs"}}
                    list={ModelTrainingRunList} create={ModelTrainingRunCreate}/>
          : null,
        permissions && permissions.has('admin') ?
          <Resource name="integrationservice" options={{"label": "Integration Services"}}
                    list={IntegrationServiceList} create={IntegrationServiceCreate}
                    edit={IntegrationServiceEdit}/>
          : null,
      ].sort((a,b) => {return a.props.name.localeCompare(b.props.name)})}
    </Admin>

  );
}

export default App;
