
import React from 'react';

import { Pagination } from 'react-admin';

import moment from "moment";

export const StandardPagination = () => <Pagination rowsPerPageOptions={[25, 50, 100]} />;

export const UserIdentifier = (record) => {
    if (record === null)
        return null
    return `${record.name} (${record.email})`
}

export const DeviceIdentifier = (record) => {
    if (record === null)
        return null

    const typeInfo = (record.type ? `(${record.type.brand} ${record.type.model})` : '');
    return `${record.serial} ${typeInfo}`
}

export const RoomWithBuildingIdentifier = (record) => {
  if (record === null)
    return null

  return `${record.building ? (record.building.name ? record.building.name : record.building.address) : ""} - ${record.nice_name ? record.nice_name : record.name}`;
}

export const formatDate = (d) => {
  // Formats date to the format DateTimeInput expects
  return moment(d).format('YYYY-MM-DDTHH:mm:ss')
}

export const recursiveDeleteNulls = (object) => {
    let keys = Object.keys(object)
    for (const key of keys) {
        if (object[key] === null){
            delete object[key]
        }
        if(object[key] instanceof Object){
            object[key] = recursiveDeleteNulls(object[key])
        }
    }
    return object
}