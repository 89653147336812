
import React from 'react';

import {
    List,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,
    AutocompleteInput,
    FunctionField,
    ArrayField,
    DateField, DateInput, DateTimeInput, BooleanInput, FormDataConsumer,
} from 'react-admin';

import { TextField as MuiTextField } from '@material-ui/core'

import {
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    SearchInput,
    NumberInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    ArrayInput,
    SimpleFormIterator,
} from 'react-admin';

import {
    StandardPagination,
    RoomWithBuildingIdentifier,
    formatDate,
} from '../common.js'

import { BulkUpdateButton } from 'react-admin';
import { Fragment, useState } from 'react';
import {
    Button,
    Confirm,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'react-admin';

import { makeStyles } from "@material-ui/core";
import moment from "moment";

import {AssetParentIdentifier} from "../resources/asset.js"

const deviceLocationFilters = [
  <ReferenceArrayInput label='Room' reference='rooms' source="room_id" perPage={10000} debounce={-1} alwaysOn>
    <AutocompleteArrayInput optionText={RoomWithBuildingIdentifier} optionValue={"id"}/>
  </ReferenceArrayInput>,

  <ReferenceArrayInput source="device_id" reference="devices" label="Device" perPage={10000} alwaysOn>
    <AutocompleteArrayInput optionText="serial"/>
  </ReferenceArrayInput>,

    <ReferenceArrayInput source="organization_id" reference="organizations" label="Organization" perPage={10000} alwaysOn inQuery>
        <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>,

  <SearchInput source="q" alwaysOn/>,
];

const useStyles = makeStyles(theme => ({
  root: {

  },
  highlight: {
  }
}));

const EndDeploymentButton = ({ selectedIds }) => {

    const [endDate, setEndDate] = useState(new Date());

    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [updateMany, { loading }] = useUpdateMany(
        'devicedeployments',
        selectedIds,
        { end_time: endDate },
        {
            onSuccess: () => {
                refresh();
                notify('Updated');
                unselectAll('devicedeployments');
            },
            onFailure: error => notify('Error: not updated', { type: 'warning' }),
        }
    );
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        updateMany();
        setOpen(false);
    };


    const handleChange = (e) => {
        const date = moment(e.target.value, "YYYY-MM-DD").toDate();
        console.log('change', e, date)
        setEndDate(date);
    }

    const classes = useStyles();
    
    return (
        <Fragment>
            <Button label="End deployments" onClick={handleClick} />

            <Confirm
                isOpen={open}
                loading={loading}
                title="End device deployments"
                content={
                  <>
                    <form className={classes.container} noValidate>
                      <MuiTextField
                        id="date"
                        label="Deployment end date"
                        size='medium'
                        fullWidth='true'
                        type="date"
                        margin="normal"
                        defaultValue={ moment(new Date()).format("YYYY-MM-DD") }
                        className={classes.textField}
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    <span>Are you sure you want to mark these device deployments as ended?</span>
                    </form>
                  </>
                }
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
}


const DeviceDeploymentBulkActionButtons = props => (
    <Fragment>
        <EndDeploymentButton label="End deployment" {...props} />
    </Fragment>
);



export const DeviceDeploymentList = props => (
  <List {...props} pagination={<StandardPagination />} perPage={25} filters={deviceLocationFilters} bulkActionButtons={<DeviceDeploymentBulkActionButtons />}>
    <Datagrid rowClick="edit">
      <TextField source="id"/>
      <ReferenceField source="device_id" reference="devices"><TextField source="serial"/></ReferenceField>
      <ReferenceField source="room_id" reference="rooms"><FunctionField
        label="Name"
        render={record => {
          return record.nice_name ? record.nice_name : record.name
        }}
      /></ReferenceField>
      <NumberField source="start_time"/>
      <TextField source="end_time"/>
      <ArrayField source="assets">
        <Datagrid rowClick="edit" style={{tableLayout: 'fixed', maxWidth: '500px'}}>
          <ReferenceField source="id" reference="assets">
            <FunctionField
                  label="Id"
                  render={record => `${record.id.split('-', 1)}-...`}
            />
          </ReferenceField>
          <ReferenceField source="asset_category_id" reference="asset_categories" label="Category"><TextField
            source="name"/></ReferenceField>
          <FunctionField
                label="Name"
                render={record => `${record.nice_name || ''} | ${record.name}`}
          />
        </Datagrid>
      </ArrayField>
    </Datagrid>
  </List>
);

export const DeviceDeploymentCreate = props => {

  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput label='Device' reference='devices' source="device_id" perPage={1000}>
          <AutocompleteInput optionText="serial"/>
        </ReferenceInput>
        <ReferenceInput label='Room' reference='rooms' source="room_id" perPage={10000}>
          <AutocompleteInput optionText={RoomWithBuildingIdentifier}/>
        </ReferenceInput>
        <DateTimeInput source="start_time" defaultValue={new Date()} format={formatDate}/>
        <DateTimeInput source="end_time" defaultValue={null} helperText="Leave blank end time for open interval"
                       format={formatDate}/>
        <ArrayInput source="assets">
          <SimpleFormIterator>
            <ReferenceInput label="Asset" reference="assets" perPage={10000}>
              <AutocompleteInput optionText={AssetParentIdentifier}/>
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <NumberInput source="roll" label="Roll"/>
        <NumberInput source="pitch" label="Pitch"/>
        <NumberInput source="yaw" label="Yaw"/>
        <NumberInput source="install_height" label="Install Height"/>
        <NumberInput source="floor_height" label="Floor Height"/>
        <TextInput source="description" label="Description"/>
        <TextInput source="meta" label="Meta"/>
      </SimpleForm>
    </Create>
  )
};

export const DeviceDeploymentEdit = props => {

  const transform = data => {
    data = {
      device_id: data.device_id,
      room_id: data.room_id,
      start_time: data.start_time,
      end_time: data.end_time,
      assets: data.assets.map(a => a.id),
      meta: data.meta,
      roll: data.room_position.roll,
      pitch: data.room_position.pitch,
      yaw: data.room_position.yaw,
      install_height: data.room_position.install_height,
      floor_height: data.room_position.floor_height,
      description: data.description
    }
    return data
  }

  return (
    <Edit {...props} transform={transform}>
      <SimpleForm>
        <ReferenceInput label='Device' reference='devices' source="device_id" perPage={10000}>
          <AutocompleteInput optionText="serial"/>
        </ReferenceInput>
        <ReferenceInput label='Room' reference='rooms' source="room_id" perPage={10000}>
          <AutocompleteInput optionText={RoomWithBuildingIdentifier}/>
        </ReferenceInput>
        <DateTimeInput source="start_time" defaultValue={new Date()} format={formatDate}/>
        <DateTimeInput source="end_time" defaultValue={null} helperText="Leave blank end time for open interval"
                       format={formatDate}/>
        <ArrayInput source="assets">
          <SimpleFormIterator>
            <ReferenceInput label="Asset" source="id" reference="assets" perPage={10000}>
              <AutocompleteInput optionText={AssetParentIdentifier}/>
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <NumberInput source="room_position.roll" label="Roll"/>
        <NumberInput source="room_position.pitch" label="Pitch"/>
        <NumberInput source="room_position.yaw" label="Yaw"/>
        <NumberInput source="room_position.install_height" label="Install Height"/>
        <NumberInput source="room_position.floor_height" label="Floor Height"/>
        <TextInput source="description" label="Description"/>
        <TextInput source="meta" label="Meta"/>
      </SimpleForm>
    </Edit>
  )
};
